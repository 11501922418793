import React from "react";
import PropTypes from "prop-types";

const HomeElevatorPitch = ({ title, desc }) => {
  return (
    <div className="haiti">
      <div className="content haiti-c">
        <div className="haiti-wr">
          <div className="haiti-half">
            <h3>{title}</h3>
          </div>
          <div className="haiti-half">
            <p className="haiti-p">{desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeElevatorPitch.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
};

HomeElevatorPitch.defaultProps = {
  title: "We're changing tax audit insurance for good.",
  desc: "AuditCover is the first direct to client, tax audit insurance delivering personalised policies and fairer pricing for accountants and taxpayers.",
};

export default HomeElevatorPitch;
