import React from "react";
import { Link, withPrefix } from "gatsby";

const icons = [
  {
    id: "uvp-icon-1",
    title: "Keep it clean",
    desc: "Your clients only pay for the tax AuditCover they need. There are no hidden fees and the cost of tax audit insurance is tax deductible.",
    icon: "images/get-paid-icon.png",
  },
  {
    id: "uvp-icon-2",
    title: "Remove admin",
    desc: "The entire workflow is managed through our seamless and secure online platform for onboarding, updates, reminders, renewals and ongoing client support.",
    icon: "images/remove-admin-icon.png",
  },
  {
    id: "uvp-icon-3",
    title: "Personalise",
    desc: "AuditCover can provide personalised quotes to your clients or you can simply create live quotes through your dashboard.",
    icon: "images/control-icon.png",
  },
];

const HomeUVP = () => {
  return (
    <div className="icons">
      <div className="content icons-c">
        <div className="icons-wr">
          {icons.map(({ id, title, desc, icon }) => (
            <div key={id} className="icons-block">
              <img
                src={withPrefix(icon)}
                alt=""
                className="icon"
                loading="lazy"
              />
              <h4>{title}</h4>
              <p className="icons-p">{desc}</p>
            </div>
          ))}
        </div>
        <Link
          to="/forms/partner-contact-form/"
          className="cta cta-mirror w-button"
        >
          Book a consultation
        </Link>
      </div>
    </div>
  );
};

export default HomeUVP;
