import React from "react";
import { withPrefix } from "gatsby";
import PropTypes from "prop-types";

const works = [
  {
    id: "work-1",
    label: "STEP 1",
    title: "Set up your account",
    desc: "Tailor the platform to your specific practice and client needs.",
    image: "images/how-1.png",
  },
  {
    id: "work-2",
    label: "STEP 2",
    title: "Launch your campaign",
    desc: "Generate an offer for tax AuditCover for each of your clients.",
    image: "images/how-2.png",
  },
  {
    id: "work-3",
    label: "STEP 3",
    title: "Monitor your performance",
    desc: "Keep an eye on the portfolio take up, and see year-on-year improvements.",
    image: "images/how-3.png",
  },
];

const HomeHowItWorks = ({ title }) => {
  return (
    <div id="how" className="steps">
      <div className="steps-wr">
        <h3 className="steps-heading">{title}</h3>
        <div className="steps-holder">
          {works.map(({ id, label, title, desc, image }) => (
            <div key={id} className="steps-row">
              <div className="steps-txt">
                <p className="blue-txt">{label}</p>
                <h4>{title}</h4>
                <p className="steps-p">{desc}</p>
                <div className="blue-dot-wr">
                  <img
                    src={withPrefix("images/blue-dot.png")}
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="steps-img">
                <img src={withPrefix(image)} alt="" loading="lazy" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

HomeHowItWorks.propTypes = {
  title: PropTypes.string,
};

HomeHowItWorks.defaultProps = {
  title: "How AuditCover tax audit insurance works",
};

export default HomeHowItWorks;
