import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PartnersBanner from "../components/PartnersBanner";
import HomeElevatorPitch from "../components/HomeElevatorPitch";
import HomeUVP from "../components/HomeUVP";
import HomeWhyUs from "../components/HomeWhyUs";
import HomeWhyAuditCover from "../components/HomeWhyAuditCover";
import HomeHowItWorks from "../components/HomeHowItWorks";
import HomeTestimonials from "../components/HomeTestimonials";
import HomeCta from "../components/HomeCta";

// Testing the image loading
const ForPartnersPage = ({ data }) => {
  const {
    banner,
    elevator,
    why,
    benefit,
    hiw,
    testimonials,
    testimonialsData,
  } = data;

  return (
    <Layout
      bg="partners-bg"
      banner={
        <PartnersBanner
          subheader={banner.frontmatter.subheader}
          header={banner.frontmatter.header}
          header2={banner.frontmatter.header2}
          ctalinktext={banner.frontmatter.ctalinktext}
          socialproof={banner.frontmatter.socialproof}
        />
      }
    >
      <HomeElevatorPitch
        title={elevator.frontmatter.header}
        desc={elevator.frontmatter.subheader}
      />
      <HomeUVP />
      <HomeWhyUs
        subtitle={why.frontmatter.subheader}
        title={why.frontmatter.header}
        desc={why.frontmatter.paragraph}
      />
      <div className="section">
        <div className="content section-c">
          <HomeWhyAuditCover
            title={benefit.frontmatter.header}
            desc={benefit.frontmatter.paragraph}
          />
          <HomeHowItWorks title={hiw.frontmatter.title} />
          <div className="what">
            <HomeTestimonials
              data={testimonialsData?.edges}
              title={testimonials?.frontmatter?.title}
            />
          </div>
        </div>
      </div>
      <HomeCta />
    </Layout>
  );
};

export default ForPartnersPage;

export const partnersQuery = graphql`
  query {
    banner: markdownRemark(
      frontmatter: { templateKey: { eq: "content-banner" } }
    ) {
      id
      frontmatter {
        subheader
        header
        header2
        ctalinktext
        socialproof
      }
    }
    elevator: markdownRemark(
      frontmatter: { templateKey: { eq: "content-elevator" } }
    ) {
      id
      frontmatter {
        header
        subheader
      }
    }
    why: markdownRemark(
      frontmatter: { templateKey: { eq: "content-whyauditcover" } }
    ) {
      id
      frontmatter {
        subheader
        header
        paragraph
      }
    }
    benefit: markdownRemark(
      frontmatter: { templateKey: { eq: "content-benefits" } }
    ) {
      id
      frontmatter {
        header
        paragraph
      }
    }
    hiw: markdownRemark(frontmatter: { templateKey: { eq: "content-hiw" } }) {
      id
      frontmatter {
        title
      }
    }
    testimonials: markdownRemark(
      frontmatter: { templateKey: { eq: "content-testimonials" } }
    ) {
      id
      frontmatter {
        title
      }
    }
    testimonialsData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "testimonials" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            photo {
              publicURL
            }
            person
            company
            type
            title
            contents
          }
        }
      }
    }
  }
`;
