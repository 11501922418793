import React from "react";
import { withPrefix } from "gatsby";

const HomeClientItem = ({
  avatar,
  title,
  subtitle,
  authorName,
  authorTitle,
}) => {
  return (
    <div className="nu-client-item">
      <img src={withPrefix(avatar)} alt={authorName} loading="lazy" />
      <h1>{title}</h1>
      <span>{subtitle}</span>
      <div>
        {`${authorName},`}
        <span>{authorTitle}</span>
      </div>
    </div>
  );
};

export default HomeClientItem;
