import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const whats = [
  {
    id: "what-1",
    content: "Capital Gains Tax",
  },
  {
    id: "what-2",
    content: "Income Tax",
  },
  {
    id: "what-3",
    content: "Land Tax",
  },
  {
    id: "what-4",
    content: "Payroll Tax",
  },
  {
    id: "what-5",
    content: "BAS/GST Compliance",
  },
  {
    id: "what-6",
    content: "Superannuation Guarantee",
  },
  {
    id: "what-7",
    content: "Fringe Benefits Tax",
  },
  {
    id: "what-8",
    content: "Stamp Duty",
  },
];

const whos = [
  {
    id: "who-1",
    content: "Individuals (PAYG)",
  },
  {
    id: "who-2",
    content: "Sole traders",
  },
  {
    id: "who-3",
    content: "Partnerships",
  },
  {
    id: "who-4",
    content: "Companies",
  },
  {
    id: "who-5",
    content: "Trusts",
  },
  {
    id: "who-6",
    content: "Self-managed\n superannuation funds\n (SMSFs)",
  },
];

const HomeHowItWorks = ({ title, desc, buttonLabel }) => {
  return (
    <div className="clients">
      <div className="left-right-wr">
        <div className="left-right-half">
          <h4 className="needs-heading">{title}</h4>
        </div>
        <div className="left-right-half">
          <p className="left-right-p pre-line">{desc}</p>
        </div>
      </div>
      <div className="column-holder">
        <div className="column-header column-left">
          <h4>What we cover</h4>
          <p>
            (We cover your customers for audits arising in relation to the following types
            of taxes and levies)
          </p>
        </div>
        <div
          id="w-node-_9aeb70e2-5604-606a-a561-1eb450e98aa0-1e6ad2d3"
          className="column-header column-right"
        >
          <h4>Who we cover</h4>
        </div>
        <div
          id="w-node-_9d663f5e-b3ef-3bc4-98b6-e63ab4569d72-1e6ad2d3"
          className="column-content column-left"
        >
          <ul className="list w-list-unstyled">
            {whats.map(({ id, content }) => (
              <li key={id} className="list-item">
                {content}
              </li>
            ))}
          </ul>
        </div>
        <div className="column-content column-right">
          <ul className="list w-list-unstyled">
            {whos.map(({ id, content }) => (
              <li key={id} className="list-item">
                {content}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Link
        to="/forms/partner-contact-form/"
        className="cta cta-mirror w-button"
      >
        {buttonLabel}
      </Link>
    </div>
  );
};

HomeHowItWorks.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  buttonLabel: PropTypes.string,
};

HomeHowItWorks.defaultProps = {
  title:
    "Your clients are increasingly likely to be singled out for a triggered or random tax audit.",
  desc: "Each year 1 in 6 Australian taxpaying entities is considered for further investigation by the regulating bodies, as the ATO adopts more advanced technologies to identify anomalies in tax returns and to run randomised checks.\n\nWhether it’s due to genuine tax avoidance or an innocent mistake in information provided, the cost of being audited can run into tens of thousands of dollars.",
  buttonLabel: "Book a consultation",
};

export default HomeHowItWorks;
