import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const oldWays = [
  {
    id: "oldway-1",
    content: "Your practice is the policyholder.",
  },
  {
    id: "oldway-2",
    content:
      "Your practice on-sells group insurance to your clients, so you are responsible for the cover that they receive.",
  },
  {
    id: "oldway-3",
    content:
      "Your practice has to manage enquiries, claims, renewals, payments, and updates every year.",
  },
  {
    id: "oldway-4",
    content: "Non disclosed fees.",
  },
];

const newWays = [
  {
    id: "newway-1",
    content: "The client is the policyholder.",
  },
  {
    id: "newway-2",
    content:
      "Quotes are sent directly to your clients eliminating practice obligations for you and your team.",
  },
  {
    id: "newway-3",
    content:
      "Your clients are in control of their own policy, meaning no renewal admin for the firm, and ongoing client support.",
  },
  {
    id: "newway-4",
    content: "Transparent pricing.",
  },
];

const HomeWhyUs = ({ subtitle, title, desc, buttonLabel }) => {
  return (
    <div id="why" className="needs">
      <div className="content needs-c">
        <div className="left-right-wr">
          <div className="left-right-half">
            <p className="blue-txt">{subtitle}</p>
            <h4 className="needs-heading">{title}</h4>
          </div>
          <div className="left-right-half">
            <p className="single">{desc}</p>
          </div>
        </div>
        <div className="column-holder">
          <div className="column-header">
            <h4 className="column-heading">Old Way</h4>
          </div>
          <div
            id="w-node-_7821ba96-4cb5-12a3-648e-8bca7c7afd8c-1e6ad2d3"
            className="column-header autditcover"
          >
            <h4 className="column-heading">AuditCover Way</h4>
          </div>
          <div
            id="w-node-_3a558fd8-0bb9-bc28-3160-8d6558f66db2-1e6ad2d3"
            className="column-content"
          >
            <ul className="list w-list-unstyled">
              {oldWays.map(({ id, content }) => (
                <li key={id} className="list-item second">
                  {content}
                </li>
              ))}
            </ul>
          </div>
          <div className="column-content audticover">
            <ul className="list w-list-unstyled">
              {newWays.map(({ id, content }) => (
                <li key={id} className="list-item">
                  {content}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Link
          to="/forms/partner-contact-form/"
          className="cta cta-mirror w-button"
        >
          {buttonLabel}
        </Link>
      </div>
    </div>
  );
};

HomeWhyUs.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  buttonLabel: PropTypes.string,
};

HomeWhyUs.defaultProps = {
  subtitle: "WHY AUDITCOVER",
  title:
    "AuditCover has evolved to reflect the needs of today’s accounting industry.",
  desc: "We take care of the entire Tax Audit insurance workflow for you, making life easier for both you and your clients.",
  buttonLabel: "Book a consultation",
};

export default HomeWhyUs;
