import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const HomeCta = ({ title, desc, buttonLabel }) => {
  return (
    <div className="haiti">
      <div className="content haiti-c">
        <div className="haiti-wr">
          <div className="haiti-half">
            <h2>{title}</h2>
          </div>
          <div className="haiti-half">
            <p className="arrange-p">{desc}</p>
            <Link
              to="/forms/partner-contact-form/"
              className="cta-white w-button"
            >
              {buttonLabel}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeCta.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  buttonLabel: PropTypes.string,
};

HomeCta.defaultProps = {
  title: "Arrange your obligation-\n free consultation",
  desc: "Learn how you can use AuditCover to give your clients the tax audit protection they need and instantly reduce the workload for your practice.",
  buttonLabel: "Book a consultation",
};

export default HomeCta;
