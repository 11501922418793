import React from "react";
import PropTypes from "prop-types";
import { Link, withPrefix } from "gatsby";

const PartnersBanner = ({
  subheader,
  header,
  header2,
  ctalinktext,
  socialproof,
}) => {
  return (
    <div className="banner">
      <div className="content nu-alt2-c">
        <div className="banner-wr" style={{ alignItems: "flex-end" }}>
          <div className="banner-txt text-white nu-c">
            <h1>{header}</h1>
            <p className="banner-p text-white">{header2}</p>
            <div className="banner-btn-group">
              <a
                href="/forms/contact-us/"
                className="cta cta-top long w-button cta-top-mirror"
              >
                Get a quote
              </a>
              <Link
                to="/forms/partner-contact-form/"
                className="cta long w-button cta-top-mirror btn-secondary"
              >
                Request a demo
              </Link>
            </div>
          </div>
          <div className="banner-img">
            <div className="banner-img-partner">
              <img
                src={withPrefix("images/hero-pa.png")}
                alt="Tax audit insurance made easy"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PartnersBanner.propTypes = {
  subheader: PropTypes.string,
  header: PropTypes.string,
  header2: PropTypes.string,
  ctalinktext: PropTypes.string,
  socialproof: PropTypes.string,
};

PartnersBanner.defaultProps = {
  subheader: "Tax audit insurance made easy",
  header: "Give your clients the tax audit protection they need",
  header2: "and reduce the workload for your practice.",
  ctalinktext: "Book  your obligation-free consultation",
  socialproof: "TAX AGENTS AND ACCOUNTANTS USING AUDITCOVER:",
};

export default PartnersBanner;
