import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import HomeClientItem from "./HomeClientItem";

const settings = {
  autoplay: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  className: "slider slider-two",
};

const HomeTestimonials = ({ data, title }) => {
  return (
    <div className="testimonials">
      <h3 className="test-heading">{title}</h3>
      <Slider {...settings}>
        {data.map(({ node }) => (
          <div key={node?.id}>
            <HomeClientItem
              avatar={node?.frontmatter?.photo?.publicURL}
              title={node?.frontmatter?.title}
              subtitle={node?.frontmatter?.contents}
              authorName={node?.frontmatter?.person}
              authorTitle={node?.frontmatter?.company}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

HomeTestimonials.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
};

HomeTestimonials.defaultProps = {
  title:
    "What tax agents and accountants think about AuditCover’s tax audit insurance",
};

export default HomeTestimonials;
